@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: Raleway, system-ui, sans-serif;
  }
}

@layer components {
  .btn {
    @apply py-2 px-4 bg-secondary text-white h-9 hover:bg-secondary-100 text-center flex justify-center items-center transition-all;
  }
  .btn-full {
    @apply w-full
  }
  aside h1, article h1 {
    @apply text-2xl font-semibold mb-4
  }
 aside p, article p {
    @apply text-base font-normal mb-4
  }

  .content-container {
    @apply w-[78%] lg:w-11/12 lg:max-w-[1600px]  m-auto;
  }

  main {
    @apply py-14
  }

  .pagination {
    @apply flex flex-row gap-3 justify-center mt-5 text-secondary
  }
  .pagination li.selected {
    @apply border-b-2
  }
  .rc-slider-handle,  .rc-slider-handle-2 {
    @apply w-3 bg-secondary border-secondary 
  }
  .form-control {
    @apply relative min-h-[32px] border mt-[6px]
  }
}
.rc-slider-track, .rc-slider-track-1, .rc-slider-step {
  z-index: 1;
  background-color: rgb(0, 38, 75) !important;
  height: 3px !important;
}
.rc-slider-handle,  .rc-slider-handle-2 {
  z-index: 999;
  width: 10px !important;
  height: 20px !important;
  border-radius: 0 !important;
  border: none !important;
  background-color: rgb(0, 38, 75) !important;
  transform: translate(-50%, -50%) !important;
  top: 12px !important;
}
.rc-slider-handle:active {
  box-shadow: none !important;
  border: none !important;

}
.filled.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

svg {
  animation: 2s linear infinite svg-animation;
  max-width: 50px;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
}

circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: #2f3d4c;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }
  
  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  
  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}