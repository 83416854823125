  
  .input-container {
    position: relative;
  }
  
  .input {
    border: 0;
    box-sizing: border-box;
    height: 100%;
    outline: 0;
    padding: 4px 12px 0;
    width: 100%;
  }
  
  .placeholder {
    left: 9px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms;
    transform: translateY(-50%) ;
    top: 50%;
    padding: 1px 3px;
  }
  
  .input:focus ~ .placeholder,
  .input:not(:placeholder-shown) ~ .placeholder {
    color: #004d98;
    background-color:white;
    transform: translateY(-28px) translateX(0) scale(0.75);
  }