@tailwind components;

.swiper-pagination-bullet {
  background-color: white;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  color: white;
  background: white;
  opacity: 1;
}

[data-id="tooltip"],
[data-id="tooltip"]::before,
[data-id="tooltip"]::after {
  color: rgba(0, 38, 75, 1) !important;
  background-color: #DFDFDF !important;
  border-color: #DFDFDF !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: white;
}